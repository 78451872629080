#navbar {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 60px;
    position: relative;
}

#navbar .logo-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-right: 20px;
}

#navbar .logo-wrapper img{
    height: 40px;
}

#navbar .nav-items-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    flex: 1;
    max-width: 1000px;
}

#navbar .nav-items-wrapper a {
    font-size: 19px;
    transition: 200ms ease-out;
}

#navbar .nav-items-wrapper a:hover {
    text-decoration: none;
    transform: scale(1.1);
    color: inherit;
    font-weight: inherit;
}

#navbar .hamburger-wrapper {
    display: none;
}

#navbar .hamburger-wrapper .hamburger-list {
    position: absolute;
    top: 60px;
    left: 0;
    right: 0;
    background: white;
    overflow: hidden;
    height: 100vh;
    
    transition: 300ms ease-out;
}

#navbar .hamburger-wrapper .hamburger-list.closed {
    height: 0;
    opacity: 0;
}

#navbar .hamburger-wrapper .hamburger-list a {
    display: grid;
    grid-template-columns: 40px auto;
    width: 100%;
    padding: 12px 12px;
    transition: 200ms ease-out;

    font-size: 22px;
}

#navbar .hamburger-wrapper .hamburger-list a i.far {
    margin-right: 10px;
}

#navbar .hamburger-wrapper .hamburger-list a:hover {
    text-decoration: none;
    color: inherit;
    font-weight: inherit;
}


@media screen and (max-width: 950px) {
    
    #navbar .nav-items-wrapper {
        display: none;
    }

    #navbar .hamburger-wrapper {
        display: flex;
    }
}