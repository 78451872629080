* {
    font-family: Arial, Helvetica, sans-serif;
}

html, body {
    height: 100%;
    width: 100%;
}

#root {
    height: 100%;
    width: 100%;
}