section.unit {
    min-height: 100%;
    background: var(--background);
    display: grid;
    grid-template-columns: 1fr 1fr;
}

section.unit .left, section.unit .right {
    align-self: center;
}

section.unit .left {
    padding: 30px;
}

section.unit .left h1.title {
    font-size: 45px;
    color: var(--title-color);
}

section.unit .left article.body {
    margin-top: 10px;
    font-size: 25px;
    color: var(--body-color);
}

section.unit .right {
    text-align: center;
}

section.unit .right img {
    width: 80%;
    border-radius: 12px;
}

@media screen and (max-width: 1200px) {
    section.unit .left h1.title {
        font-size: 36px;
    }
    
    section.unit .left article.body {
        font-size: 22px;
    }
}

@media screen and (max-width: 930px) {
    section.unit {
        grid-template-columns: 1fr;
    }
    section.unit .left h1.title {
        font-size: 45px;
    }
    
    section.unit .left article.body {
        font-size: 25px;
    }
}