.hamburger {
    background: rgb(255, 255, 255, 0);
    border: none;
    width: 60px;
    position: relative;
}

.hamburger .line {
    position: absolute;
    height: 4px;
    width: 40px;
    border-radius: 2px;
    background: black;
    left: 10px;

    transition: transform 300ms ease-out, opacity 300ms ease-out;
}


.hamburger .line.line-1 {
    top: 50%;
    transform: translateY(calc(-50% - 12px));
}

.hamburger .line.line-2 {
    top: 50%;
    transform: translateY(-50%);
}

.hamburger .line.line-3 {
    top: 50%;
    transform: translateY(calc(-50% + 12px));
}

.hamburger.active .line.line-1 {
    transform: translateY(-50%) rotateZ(45deg);
}

.hamburger.active .line.line-2 {
    opacity: 0;
}

.hamburger.active .line.line-3 {
    transform: translateY(-50%) rotateZ(-45deg);
}